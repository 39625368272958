import React from "react";
import { useWindowDimensions } from "../../common/hooks/useWindowDimensions";
import ProjectsDesktopVersion from "./projectsOnDesktop/ProjectsDesktopVersion";
import ProjectsPhoneVersion from "./projectsOnPhone/ProjectsPhoneVersion";
interface Props {
  showProject: boolean;
}

const ProjectPage: React.FC<Props> = ({ showProject }) => {
  const { width } = useWindowDimensions();

  const checkWindowDimension = () => {
    if (width <= 768) {
      return <ProjectsPhoneVersion showProject={showProject} />;
    } else {
      return <ProjectsDesktopVersion />;
    }
  };
  return <>{checkWindowDimension()}</>;
};

export default ProjectPage;
