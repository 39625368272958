import React from "react";
import { onClickGithubIcon } from "../../methods/onClickGithub";
import { projectsType } from "../../projectsContent";

interface Props {
  projectInfo: projectsType;
}

const ProjectPhoneContainer: React.FC<Props> = ({ projectInfo }) => {
  const iconSpecialStyle = (icon: string) => {
    const reactNativeIcon = "https://i.imgur.com/lUEowQb.png";
    const firebaseIcon = "https://i.imgur.com/ffuHbqB.png";
    const nodeIcon = "https://i.imgur.com/9E6JnaX.png";
    if (icon === reactNativeIcon) {
      return "36vw";
    } else if (icon === firebaseIcon) {
      return "5vw";
    } else if (icon === nodeIcon) {
      return "12vw";
    }
  };

  return (
    <div className="Project_P_Container">
      <>
        <div
          className="Project_P_Title"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {projectInfo.title}
        </div>

        <img
          className="Project_P_IMG"
          src={projectInfo.imgURL}
          alt="Project IMG"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="500"
        />

        <div
          className="Project_P_Info_Container"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="900"
        >
          <div className="Project_P_text">{projectInfo.information}</div>

          <div className="Project_P_tools_Container">
            {projectInfo.toolsUsed.map((icon, index) => (
              <img
                key={index}
                src={icon}
                style={{ width: iconSpecialStyle(icon) }}
                alt="tools used IMG"
                onClick={() => onClickGithubIcon(icon, projectInfo)}
              />
            ))}
            {projectInfo.github_URL ? (
              <img
                className="GithubProjects_ICON"
                src="https://i.imgur.com/1YNwtHU.png"
                onClick={() => window.open(projectInfo.github_URL, "_blank")}
                alt="githubIcon"
              />
            ) : (
              ""
            )}
            {projectInfo.live_URL ? (
              <img
                className="liveURL_ICON"
                src="https://i.imgur.com/jh3FeT6.png"
                onClick={() => window.open(projectInfo.live_URL, "_blank")}
                alt="Project-Website-Icon"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default ProjectPhoneContainer;
