import React from "react";
import { projectsContent } from "../../projectsContent";
import ProjectContainer1 from "./ProjectContainer1";
import ProjectContainer2 from "./ProjectContainer2";
import { ContainersType } from "./ProjectsDesktopVersion";

interface Props {
  containers: ContainersType;
}

const ProjectsHeadContainer: React.FC<Props> = ({ containers }) => {
  return (
    <div id="Projects">
      {projectsContent.map((project, index) => {
        let content = [];
        if (index === containers.container1) {
          content.push(<ProjectContainer1 key={index} projectInfo={project} />);
        }
        if (index === containers.container2) {
          content.push(<ProjectContainer2 key={index} projectInfo={project} />);
        }
        return content;
      })}
    </div>
  );
};

export default ProjectsHeadContainer;
