import React from "react";
import { useTypeAnimation } from "../../MainPage/hooks/useTypeAnimation";

const MenuBar: React.FC = () => {
  const { text } = useTypeAnimation("BrianVelizBV@outlook.com", 50);

  const ScrollToProjects = () => {
    window.scrollTo({
      top: 9999,
      behavior: "smooth",
    });
  };
  return (
    <div id="menu-bar">
      <img
        id="main-Logo"
        src="img/Logo.png"
        alt="My logo"
        onClick={() => window.location.reload()}
      />
      <div id="contact-Box">
        <div id="mail-logo-container">
          <img
            src="img/mailLogo.png"
            alt="mail logo"
            onClick={() => {
              window.location.href = "mailto:brianvelizbv@outlook.com";
            }}
          />
          <span ref={text} />
        </div>
        <img
          src="img/githublogo.png"
          alt="github logo"
          onClick={() => window.open("https://github.com/Libre255", "_blank")}
        />
        <div id="menuCVAndProjects-btn">
          <div
            className="btn-Cv-Project"
            onClick={() => window.open("BrianVelizCV.pdf", "_blank")}
          >
            CV
          </div>
          <div className="btn-Cv-Project projectbtn-menu" onClick={ScrollToProjects}>
            Projects 2021
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
