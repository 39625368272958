import { useEffect } from "react";
import AOS from "aos";

const useAOSanimations = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
};

export { useAOSanimations };
