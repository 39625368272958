import React from "react";
import { iconSpecialStyle } from "../../methods/iconSpecialStyle";
import { onClickGithubIcon } from "../../methods/onClickGithub";
import { ProjectContainerProps } from "./projectContainerProps";

const ProjectContainer1: React.FC<ProjectContainerProps> = ({
  projectInfo,
}) => {
  return (
    <div id="project1" className="project-container">
      <div className="project-info-container">
        <div
          className="project-title"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          {projectInfo.title}
        </div>
        <div
          id="project-1-description"
          className="project-information"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <span>{projectInfo.information}</span>

          <div className="tools-used">
            {projectInfo.toolsUsed.map((icon, index) => (
              <img
                key={index}
                style={{ maxWidth: iconSpecialStyle(icon) }}
                src={icon}
                alt="tools used IMG"
                onClick={() => onClickGithubIcon(icon, projectInfo)}
              />
            ))}
            {projectInfo.github_URL ? (
              <img
                className="GithubProjects_ICON"
                src="https://i.imgur.com/1YNwtHU.png"
                onClick={() => window.open(projectInfo.github_URL, "_blank")}
                alt="githubIcon"
              />
            ) : (
              ""
            )}
            {projectInfo.live_URL ? (
              <img
                className="liveURL_ICON"
                src="https://i.imgur.com/jh3FeT6.png"
                onClick={() => window.open(projectInfo.live_URL, "_blank")}
                alt="Project-Website-Icon"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div id="project1-Main-Img">
        <img
          src={projectInfo.imgURL}
          alt="project Imagen"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="500"
        />
      </div>
    </div>
  );
};

export default ProjectContainer1;
