import { useLayoutEffect, useState } from "react";

const useShowProjects = () => {
  const [showProjects, setShowProjects] = useState(false);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 10 && !showProjects) {
        setShowProjects(true);
      } else if (window.scrollY < 10 && showProjects) {
        setShowProjects(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [showProjects]);

  return { showProjects };
};

export { useShowProjects };
