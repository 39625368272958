import React, { useLayoutEffect, useState } from "react";
import ArrowAnimation from "../../../MainPage/Components/ArrowAnimation";
import { projectsContent } from "../../projectsContent";
import ProjectPhoneContainer from "./ProjectPhoneContainer";

interface Props {
  showProject: boolean;
}
const ProjectsPhoneVersion: React.FC<Props> = ({ showProject }) => {
  const [showProjectArrow, setShowProjectArrow] = useState(true);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= 4000 && showProjectArrow) {
        setShowProjectArrow(false);
      } else if (window.scrollY < 4000 && !showProjectArrow) {
        setShowProjectArrow(true);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [showProjectArrow]);
  return (
    <div id="Projects_PhoneVersion">
      {projectsContent.map((project, index) => (
        <ProjectPhoneContainer key={index} projectInfo={project} />
      ))}
      {showProject && showProjectArrow ? (
        <ArrowAnimation arrowExtraStyle="arrowForProject" />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProjectsPhoneVersion;
