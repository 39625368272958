const iconSpecialStyle = (icon: string) => {
  const reactNativeIcon = "https://i.imgur.com/lUEowQb.png";
  const firebaseIcon = "https://i.imgur.com/ffuHbqB.png";

  if (icon === reactNativeIcon) {
    return "9vw";
  } else if (icon === firebaseIcon) {
    return "1.3vw";
  }
};
export { iconSpecialStyle };
