import React from "react";
import { useZAxisAnimation } from "../hooks/useZAxisAnimation";
import ArrowAnimation from "./ArrowAnimation";
import FirstSlide from "./FirstSlide";

interface Props {
  showArrow: boolean;
}
const FrontPage: React.FC<Props> = ({ showArrow }) => {
  useZAxisAnimation();

  return (
    <div id="Front-Page">
      <div className="viewport">
        <div className="scene3D-container">
          <div className="scene3D">
            <FirstSlide />
          </div>
        </div>
      </div>
      {!showArrow ? <ArrowAnimation /> : ""}
    </div>
  );
};

export default FrontPage;
