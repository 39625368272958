import { useEffect, useRef } from "react";
import Typed from "typed.js";

const useTypeAnimation = (insertText: string, speed = 15) => {
  const text = useRef<any>(null);
  const typed = useRef<any>(null);

  useEffect(() => {
    const options = {
      strings: [insertText],
      typeSpeed: speed,
      backSpeed: 50,
      showCursor: false,
    };

    typed.current = new Typed(text.current, options);
    typed.current.destroy();

    return () => {
      typed.current.destroy();
    };
  }, [insertText, speed]);

  return { text, typed };
};

export { useTypeAnimation };
