import React from "react";
interface Props {
  arrowExtraStyle?: string;
}
const ArrowAnimation: React.FC<Props> = ({ arrowExtraStyle }) => {
  const ScrollToProjects = () => {
    window.scrollTo({
      top: 9999,
      behavior: "smooth",
    });
  }
  return (
    <div onClick={()=>ScrollToProjects()} className={`arrow ${arrowExtraStyle}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default ArrowAnimation;
