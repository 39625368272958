import React, { useState } from "react";
import ProjectMenu from "./ProjectMenu";
import ProjectsHeadContainer from "./ProjectsHeadContainer";

export interface ContainersType {
  container1: number;
  container2: number;
}

const ProjectsDesktopVersion: React.FC = () => {
  return (
    <div id="Project-Page">
      <SharedContainer />
    </div>
  );
};

const SharedContainer: React.FC = () => {
  const [containers, setContainers] = useState<ContainersType>({
    container1: 0,
    container2: 1,
  });
  return (
    <>
      <ProjectMenu setContainers={setContainers} />
      <ProjectsHeadContainer containers={containers} />
    </>
  );
};

export default ProjectsDesktopVersion;
