import { useShowProjects } from "./common/hooks/useShowProjects";
import FrontPage from "./MainPage/Components/FrontPage";
import MenuBar from "./menu/components/MenuBar";
import ProjectPage from "./projectsPage/components/ProjectPage";
import "aos/dist/aos.css";
import { useAOSanimations } from "./common/hooks/useAOSanimations";

function App() {
  const { showProjects } = useShowProjects();
  useAOSanimations();

  return (
    <div className="App">
      <MenuBar />
      <FrontPage showArrow={showProjects} />
      <ProjectPage showProject={showProjects} />
    </div>
  );
}

export default App;
