export interface projectsType {
  title: string;
  information: string;
  imgURL: string;
  toolsUsed: string[];
  github_URL?: string;
  live_URL?: string;
}

const projectsContent: projectsType[] = [
  {
    title: "Jelly Hive",
    information:
      "Internship at Jelly Hive. Created their expenses page. Data fetched with services and hooks. Mechanics like sort by date, create new expense, edit expense and more.",
    imgURL: "https://media.giphy.com/media/Kjxq7CFFJ85fHFETkZ/giphy.gif",
    toolsUsed: [
      "https://i.imgur.com/YvKEp2z.png",
      "https://i.imgur.com/8baFGv3.png",
      "https://i.imgur.com/OTUR9Nb.png",
    ],
  },
  {
    title: "Memory Cards",
    information:
      "Find the matching card! Mechanics: card position reacts to match and 'smash bar'. Submit the score to the Firebase DB via REST API with express. Created to learn about full-stack development.",
    imgURL: "https://media.giphy.com/media/nYnzrMHe78YyMgc8g3/giphy.gif",
    toolsUsed: [
      "https://i.imgur.com/YvKEp2z.png",
      "https://i.imgur.com/8baFGv3.png",
      "https://i.imgur.com/ffuHbqB.png",
      "https://i.imgur.com/9E6JnaX.png",
    ],
    github_URL: "https://github.com/Libre255/CardMemories",
    live_URL: "https://cardmemories.herokuapp.com/",
  },
  {
    title: "Vending machine",
    information:
      "A console that represents a real vending machine with a list of products, learning the basics of C# and xUnit",
    imgURL: "https://i.imgur.com/JfqS4hJ.png",
    toolsUsed: [
      "https://i.imgur.com/g6vb10Z.png",
      "https://i.imgur.com/LRzJy1k.png"
    ],
  },
  {
    title: "Göteborg Vattenflöde",
    information:
      "A team project that showcase the water flow in different parts of Gothenburg. I worked mostly on the 'Historisk data' where the user searches for differents dates and types of the water flow data.",
    imgURL: "https://media.giphy.com/media/mj3kBAcsaLcpsAv6cj/giphy.gif",
    toolsUsed: ["https://i.imgur.com/SBYvuoi.png"],
    github_URL: "https://github.com/Libre255/GruppArbete",
  },
  {
    title: "Fantasy Fotboll",
    information:
      "Create your dream football team! A team project with the goal of learning team work and full-stack-development. Worked with the 'search/showcase player page' where players are fetched from DB.",
    imgURL: "https://media.giphy.com/media/do5ry7Mh6gGXMmylAA/giphy.gif",
    toolsUsed: [
      "https://i.imgur.com/CDkGmoa.png",
      "https://i.imgur.com/9E6JnaX.png",
      "https://i.imgur.com/NprX71Y.png",
    ],
    github_URL: "https://github.com/Talleb/fantasy",
  },
  {
    title: "Weightlifting Calc",
    information: `Select the plates you want to use, write your total weight and see what plates you need on one side (using a 20kg barbell). Created to learn about React Native.`,
    imgURL: "https://media.giphy.com/media/M6Du8dGdQxRhOr6Nkr/giphy.gif",
    toolsUsed: ["https://i.imgur.com/lUEowQb.png"],
    github_URL: "https://github.com/Libre255/Weightlifting-Weight-Calculator",
  },
];

export { projectsContent };
