import React, { useState } from "react";

interface Props {
  setContainers: React.Dispatch<
    React.SetStateAction<{
      container1: number;
      container2: number;
    }>
  >;
}

const ProjectMenu: React.FC<Props> = ({ setContainers }) => {
  const [circles, setCircles] = useState<number>(1);

  return (
    <div id="Project-Menu">
      <div id="project-dot-container">
        <span
          className="dot"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="600"
          onClick={() => {
            setCircles(1);
            setContainers({ container1: 0, container2: 1 });
          }}
        >
          {circles === 1 ? (
            <span
              className="selectedDot"
              data-aos="zoom-in"
              data-aos-duration="600"
            />
          ) : (
            ""
          )}
        </span>
        <span
          className="dot"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="700"
          onClick={() => {
            setCircles(2);
            setContainers({ container1: 2, container2: 3 });
          }}
        >
          {circles === 2 ? (
            <span
              className="selectedDot"
              data-aos="zoom-in"
              data-aos-duration="600"
            />
          ) : (
            ""
          )}
        </span>
        <span
          className="dot"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="800"
          onClick={() => {
            setCircles(3);
            setContainers({ container1: 4, container2: 5 });
          }}
        >
          {circles === 3 ? (
            <span
              className="selectedDot"
              data-aos="zoom-in"
              data-aos-duration="600"
            />
          ) : (
            ""
          )}
        </span>
      </div>
    </div>
  );
};

export default ProjectMenu;
