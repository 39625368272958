import React from "react";

const FirstSlide: React.FC = () => {
  return (
    <div id="first-slide" className={"my-fade-in"}>
      <img src="/img/frontpageSlide.png" alt="front slide IMG" />
    </div>
  );
};

export default FirstSlide;
